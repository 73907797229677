import React, { useState } from 'react';
import { message, Modal, Switch, Typography } from 'antd';
import { StyledNumberInput } from 'components/Antd/StyledNumberInput/style';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import { Member } from 'types/graphqlTypes';

const UPDATE_MEMBER = gql`
  mutation ForceMaxDepositLimit($id: ID!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input)
  }
`;

const ForceMaxDepositLimit = () => {
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };

  const [visible, setVisible] = useState(false);
  const [limitValue, setLimitValue] = useState(0);
  const [checked, setChecked] = useState(member.depositLimitLock);

  const [forceMaxDepositLimit] = useMutation(UPDATE_MEMBER);

  const handleUpdateMember = async (lockLimit: boolean) => {
    await forceMaxDepositLimit({
      variables: {
        id: member.id,
        input: {
          depositLimitLock: lockLimit,
          ...(lockLimit && {
            depositLimitLockValue: limitValue,
          }),
        },
      },
    })
      .then(() => {
        refetch();
        message.success('Force max deposit limit updated.');
      })
      .catch((error) => {
        if (error)
          message.error('Something went wrong. Please try again later.');
      })
      .finally(() => setVisible(false));
  };

  const handleChange = (value: boolean) => {
    if (value) {
      setVisible(true);
      setChecked(true);
    } else {
      handleUpdateMember(value);
      setChecked(false);
    }
  };

  const handleCancelForceMaxDeposit = () => {
    setVisible(false);
    setChecked(false);
  };

  return (
    <div className="pl-3 pr-4 pb-3 d-flex justify-content-end align-items-center">
      <Typography.Text className="mr-2">
        Force Max Deposit Limit
      </Typography.Text>
      <Switch onChange={(e) => handleChange(e)} checked={Boolean(checked)} />
      <Modal
        destroyOnClose
        title="Force Max Deposit Limit"
        visible={visible}
        onOk={() => handleUpdateMember(true)}
        onCancel={handleCancelForceMaxDeposit}
        centered
        width={380}
        okText="Confirm"
      >
        <Typography.Title level={5}>Max Deposit Limit</Typography.Title>
        <StyledNumberInput
          type="number"
          placeholder="Enter max deposit limit"
          onKeyDown={(e): void => {
            const invalidChars = ['+', '-', '.', 'e'];
            if (invalidChars.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => setLimitValue(+e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default ForceMaxDepositLimit;
