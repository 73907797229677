import { Collapse } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  GAMSTOP_AUDIT_TRAIL,
  GBG_AUDIT_TRAIL,
  LOQATE_AUDIT_TRAIL,
  REALITY_CHECK_AUDIT_TRAIL,
  REGISTRATION_AUDIT_TRAIL,
} from 'graphql/queries/auditTrail.query';
import { useNextParam } from 'hooks/useIsNext';
import ManageSmtpConfig from 'pages/components/CommonConfigurations/ManageSmtpConfig';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { useAccount, usePermissions } from 'store/accountState';
import styled from 'styled-components';
import isRow from 'utils/isRow';
import AstropayShopDetails from './AstropayShopDetails';
import AuditTrailRecords from './AuditTrailRecords';
import CoinifySettings from './CoinifySettings';
import EmailAuditTrailRecord from './EmailAuditTrailRecord';
import FeatureToggleSwitch from './FeatureToggleSwitch/FeatureToggleSwitch';
import HexopayShopDetails from './HexopayShopDetails';
import ManageCountry from './ManageCountry';
import ManageCountryCode from './ManageCountryCode';
import ManageCurrency from './ManageCurrency';
import ManageCurrencyRow from './ManageCurrencyRow';
import ManageDynamicPages from './ManageDynamicPages';
import ManageEmailContent from './ManageEmailContent';
import ManageGBG, { FinancialLightCheckGBG } from './ManageGBG';
import ManageLocalization from './ManageLocalization';
import NetellerShopDetails from './NetellerShopDetails';
import RealityCheck from './RealityCheck';
import SignUpConfig from './SignUpConfig';
import SkrillShopDetails from './SkrillShopDetails';
import WonkaPayShopDetails from './WonkaPayShopDetails';
import XtremePushConfig from './XtremePushConfig';

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  height: 100%;
  row-gap: 20px;
`;

const CommonConfigurations = () => {
  const isNext = useNextParam();
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_MANAGE_CURRENCY,
    ALLOWED_MANAGE_COUNTRY,
    ALLOWED_MANAGE_COUNTRY_CODE,
    ALLOWED_MANAGE_REALITY_CHECK,
    ALLOWED_EMAIL_AUDIT_TRAIL,
    ALLOWED_CHECK_FRAUD,
    ALLOWED_SESSION_MANAGEMENT,
    ALLOWED_HEXOPAY_SHOP_DETAILS,
    ALLOWED_NETELLER_SHOP_DETAILS,
    ALLOWED_WONKAPAY_SHOP_DETAILS,
    ALLOWED_SKRILL_SHOP_DETAILS,
    ALLOWED_MANAGE_EMAIL_CONTENT,
    ALLOWED_MANAGE_SMTP_CONFIG,
    ALLOWED_MANAGE_DYNAMIC_PAGES,
    ALLOWED_LOQATE_INTEGRATION,
    ALLOWED_CHECK_DEPOSIT_LIMIT,
    ALLOWED_SIGN_UP_CONFIG,
    ALLOWED_GBG_MANAGEMENT,
    ALLOWED_CHECK_GAMSTOP,
    ALLOWED_WITHDRAWAL_CANCELATION,
  } = collectPermissions(
    role,
    permissions,
    [
      'MANAGE_CURRENCY',
      'MANAGE_COUNTRY',
      'MANAGE_COUNTRY_CODE',
      'MANAGE_REALITY_CHECK',
      'EMAIL_AUDIT_TRAIL',
      'CHECK_FRAUD',
      'SESSION_MANAGEMENT',
      'HEXOPAY_SHOP_DETAILS',
      'NETELLER_SHOP_DETAILS',
      'WONKAPAY_SHOP_DETAILS',
      'SKRILL_SHOP_DETAILS',
      'MANAGE_EMAIL_CONTENT',
      'MANAGE_SMTP_CONFIG',
      'MANAGE_DYNAMIC_PAGES',
      'LOQATE_INTEGRATION',
      'CHECK_DEPOSIT_LIMIT',
      'SIGN_UP_CONFIG',
      'GBG_MANAGEMENT',
      'CHECK_GAMSTOP',
      'WITHDRAWAL_CANCELATION',
    ],
    ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_COMMON_CONFIG
  );

  const {
    account: { config },
  } = useAccount();

  const featureToggles = [
    {
      shown: ALLOWED_CHECK_FRAUD,
      label: 'Check Fraud',
      updateInput: 'checkFraud',
      testId: 'feature-toggle-check-fraud',
      config: config?.checkFraud,
      successMsg: 'Check fraud updated.',
    },
    {
      shown: ALLOWED_SESSION_MANAGEMENT,
      label: 'Session Management',
      updateInput: 'multipleSession',
      testId: 'feature-toggle-session-management',
      config: config?.multipleSession,
      successMsg: 'Multiple session updated.',
    },
    {
      shown: ALLOWED_LOQATE_INTEGRATION,
      label: 'Loqate Feature',
      updateInput: 'enableLoqateIntegration',
      testId: 'feature-toggle-loqate',
      config: config?.enableLoqateIntegration,
    },
    {
      shown: ALLOWED_CHECK_DEPOSIT_LIMIT,
      label: 'Check Deposit Limit',
      updateInput: 'checkDepositLimit',
      testId: 'feature-toggle-check-deposit-limit',
      config: config?.checkDepositLimit,
      successMsg: 'Check Deposit Limit updated.',
    },
    {
      shown: ALLOWED_CHECK_GAMSTOP,
      label: 'GAMSTOP',
      updateInput: 'checkGamstop',
      testId: 'feature-toggle-gamstop',
      config: config?.checkGamstop,
      successMsg: 'GAMSTOP config changed successfully.',
    },
    {
      shown: ALLOWED_WITHDRAWAL_CANCELATION && isRow,
      label: 'Withdrawal Cancellation',
      updateInput: 'enableWithdrawalCancelation',
      testId: 'feature-toggle-withdrawal-cancellation',
      config: config?.enableWithdrawalCancelation,
      successMsg: 'Withdrawal Cancellation updated.',
    },
  ];

  return (
    <div
      style={{
        background: 'white',
        padding: '20px',
        height: 'calc(100vh - 112px)',
        overflow: 'auto',
      }}
    >
      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Collapse.Panel header="Management Config" key="1">
          <StyledDiv>
            {ALLOWED_MANAGE_CURRENCY && !isRow ? (
              <ManageCurrency />
            ) : (
              // Apply permissions when available
              <ManageCurrencyRow />
            )}
            {ALLOWED_MANAGE_COUNTRY && <ManageCountry />}
            {ALLOWED_MANAGE_COUNTRY_CODE && <ManageCountryCode />}
            {ALLOWED_MANAGE_EMAIL_CONTENT && <ManageEmailContent />}
            {ALLOWED_MANAGE_REALITY_CHECK && <RealityCheck />}
            {ALLOWED_MANAGE_DYNAMIC_PAGES && <ManageDynamicPages />}
            {ALLOWED_MANAGE_SMTP_CONFIG && <ManageSmtpConfig />}
            {ALLOWED_HEXOPAY_SHOP_DETAILS && <HexopayShopDetails />}
            {ALLOWED_SIGN_UP_CONFIG && <SignUpConfig />}
            {ALLOWED_GBG_MANAGEMENT && <ManageGBG />}
            {!isRow && isNext && <FinancialLightCheckGBG />}
            {ALLOWED_NETELLER_SHOP_DETAILS && <NetellerShopDetails />}
            {ALLOWED_SKRILL_SHOP_DETAILS && <SkrillShopDetails />}
            {ALLOWED_WONKAPAY_SHOP_DETAILS && isRow && <WonkaPayShopDetails />}
            {isRow && <AstropayShopDetails />}
            {isRow && isNext && <CoinifySettings />}
            <XtremePushConfig />
            {isRow && isNext && <ManageLocalization />}
          </StyledDiv>
        </Collapse.Panel>
        <Collapse.Panel header="Audit Trail Reports" key="2">
          <StyledDiv>
            {ALLOWED_EMAIL_AUDIT_TRAIL && <EmailAuditTrailRecord />}

            {/* Todo add permissions when API is available */}
            <AuditTrailRecords
              auditTrailRecord="loqateAuditTrail"
              query={LOQATE_AUDIT_TRAIL}
              title="Loqate Audit Trail"
              subTitle="Loqate"
            />
            <AuditTrailRecords
              auditTrailRecord="realityCheckAuditTrail"
              query={REALITY_CHECK_AUDIT_TRAIL}
              title="Reality Check Audit Trail"
              subTitle="Reality Check"
            />
            <AuditTrailRecords
              auditTrailRecord="registrationAuditTrail"
              query={REGISTRATION_AUDIT_TRAIL}
              title="Sign Up Config Audit Trail"
              subTitle="Sign Up Config"
            />
            <AuditTrailRecords
              auditTrailRecord="gbgAuditTrail"
              query={GBG_AUDIT_TRAIL}
              title="GBG Audit Trail"
              subTitle="GBG"
            />
            <AuditTrailRecords
              auditTrailRecord="gamstopAuditTrail"
              query={GAMSTOP_AUDIT_TRAIL}
              title="GAMSTOP Audit Trail"
              subTitle="GAMSTOP"
            />
          </StyledDiv>
        </Collapse.Panel>
        <Collapse.Panel header="Feature Toggles" key="3">
          <StyledDiv>
            {featureToggles.map((q, i) => (
              <div key={i}>
                {q?.shown && (
                  <FeatureToggleSwitch
                    label={q.label}
                    updateInput={q.updateInput}
                    testId={q.testId}
                    config={q.config}
                    successMsg={q.successMsg}
                  />
                )}
              </div>
            ))}
          </StyledDiv>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default CommonConfigurations;
